<template>
  <dropdown align="right">
    <template v-slot:button="{ open }">
      <avatar :text="initials" clickable @click="open"></avatar>
    </template>
    <template v-slot:panel>
      <div class="card">
        <div class="card-body p-1">
          <nav class="nav flex-column">
            <router-link class="nav-link" to="/account">Mon compte</router-link>
            <a class="nav-link" href="" @click.prevent="handleLogout">Déconnexion</a>
          </nav>
        </div>
      </div>
    </template>
  </dropdown>
</template>

<script>
import Avatar from '@/components/common/Avatar'
import Dropdown from '@/components/common/Dropdown'

export default {
  components: {
    Avatar,
    Dropdown
  },
  computed: {
    initials () {
      return this.$store.getters.getUserInitials
    }
  },
  methods: {
    handleLogout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style scoped lang="scss">
.nav-link {
  color: #212529;
}
</style>
