import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      default: null
    },
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    options: {
      type: Array,
      default () {
        return []
      }
    }
  }
})
