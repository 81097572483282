import { find } from 'lodash-es'
import firebase from '@/firebase'
import config from '@/config'
import router from '@/router'
import http from '@/utils/http'
import { StoreUser } from '@/store/types/users'
import { StoreObject } from '@/store/types/stores'

interface AuthState {
  authenticated: boolean;
  user: UserObject;
  userProviderId: string | null;
  userFetched: boolean;
  stores: StoreObject[];
  currentStoreId: string | null;
}

interface UserObject {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
}

export default {
  state: {
    authenticated: false,
    user: {},
    userProviderId: null,
    userFetched: false,
    stores: [],
    currentStoreId: null
  },

  getters: {
    hasAdminPrivilege: (state: AuthState) => {
      // Note : not used for now
      const role = state.user && state.user.role ? state.user.role : null
      return role === 'superadmin' || role === 'admin'
    },
    getUserProviderId: (state: AuthState) => {
      return state.userProviderId
    },
    getUserInitials: (state: AuthState) => {
      if (state.user) {
        const firstLetter = state.user.firstName ? state.user.firstName.substr(0, 1) : ''
        const lastLetter = state.user.lastName ? state.user.lastName.substr(0, 1) : ''
        return `${firstLetter}${lastLetter}`
      }
      return ''
    },
    isRegistrationCompleted: (state: AuthState): boolean => {
      const currentStore = state.currentStoreId ? find(state.stores, { id: state.currentStoreId }) : null
      return (currentStore && currentStore.registrationStatus === 'completed') as boolean
    },
    hasSkippedOnboarding: (state: AuthState): boolean => {
      const currentStore = state.currentStoreId ? find(state.stores, { id: state.currentStoreId }) : null
      return (currentStore && currentStore.hasSkippedOnboarding) as boolean
    },
    isSuspended: (state: AuthState): boolean => {
      const currentStore = state.currentStoreId ? find(state.stores, { id: state.currentStoreId }) : null
      return (currentStore && currentStore.isSuspended) as boolean
    },
    getCurrentRegistrationStep: (state: AuthState): string | null => {
      const currentStore = state.currentStoreId ? find(state.stores, { id: state.currentStoreId }) : null
      return currentStore ? currentStore.registrationStatus : null
    },
    getCurrentStore: (state: AuthState): StoreObject | null | undefined => {
      return state.stores.find(x => x.id === state.currentStoreId) ? state.stores.find(x => x.id === state.currentStoreId) : null
    }
  },

  mutations: {
    USER_AUTHENTICATED: (state: AuthState, uid: string) => {
      state.authenticated = true
      state.userProviderId = uid
    },

    USER_LOGGED_OUT: (state: AuthState) => {
      state.authenticated = false
      state.user = {}
      state.userFetched = false
      state.userProviderId = null
      state.stores = []
      state.currentStoreId = null
    },

    CURRENT_USER_FETCHED: (state: AuthState, user: UserObject) => {
      state.user = user
      state.userFetched = true
    },

    USER_STORES_FETCHED: (state: AuthState, stores: StoreObject[]) => {
      state.stores = stores
      state.currentStoreId = stores && stores[0] ? stores[0].id : null
    },

    UPDATE_REGISTRATION_STATUS: (state: AuthState, newStatus: string) => {
      const currentStore: StoreObject | undefined = state.currentStoreId ? find(state.stores, { id: state.currentStoreId }) : undefined
      const currentStoreIndex = currentStore ? state.stores.indexOf(currentStore) : -1

      if (currentStore && currentStoreIndex > -1) {
        state.stores.splice(currentStoreIndex, 1, {
          ...currentStore,
          registrationStatus: newStatus
        })
      }
    },

    SKIP_REGISTRATION: (state: AuthState, hasSkippedOnboarding: boolean) => {
      const currentStore: StoreObject | undefined = state.currentStoreId ? find(state.stores, { id: state.currentStoreId }) : undefined
      const currentStoreIndex = currentStore ? state.stores.indexOf(currentStore) : -1

      if (currentStore && currentStoreIndex > -1) {
        state.stores.splice(currentStoreIndex, 1, {
          ...currentStore,
          hasSkippedOnboarding
        })
      }
    }
  },

  actions: {
    async login ({ commit, dispatch }: any, { username, password }: { username: string; password: string }): Promise<void> {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      const { user } = await firebase.auth().signInWithEmailAndPassword(username, password)

      if (user) {
        localStorage.setItem(config.auth.userKeyName, user.uid)

        const fetchedUser = await dispatch('fetchCurrentUser')
        const stores = await dispatch('fetchUserStores')

        if (fetchedUser && stores) {
          commit('USER_AUTHENTICATED', user.uid)
        }
      }
    },

    async logout ({ commit, dispatch }: any): Promise<void> {
      await firebase.auth().signOut()
      localStorage.removeItem(config.auth.userKeyName)
      await commit('USER_LOGGED_OUT')
      await dispatch('resetAll', null, { root: true })
      router.push(config.auth.guestRedirectPath)
    },

    async fetchCurrentUser ({ commit, state }: any): Promise<UserObject | null> {
      if (state.userProviderId) {
        const { data: user } = await http.get(`/v1/users/provider-id/${state.userProviderId}`)
        commit('CURRENT_USER_FETCHED', user)
        return user
      }

      return null
    },

    async fetchUserStores ({ state, commit }: any): Promise<StoreObject[] | null> {
      const { data: storeUsers } = await http.get<StoreUser[]>(`/v1/users/${state.user.id}/stores`)

      if (storeUsers && Array.isArray(storeUsers)) {
        const stores = storeUsers.map(item => item.store)
        commit('USER_STORES_FETCHED', stores)
        return stores
      }

      return null
    }
  }
}
