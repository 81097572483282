<template>
  <tr>
    <td v-for="col in cols" :key="`col-${col.property}`" :class="[{ 'p-0': link }, col.class]">
      <template v-if="link">
        <router-link class="cell-link" :to="link">
          <cell-value :col="col" :object="object"></cell-value>
        </router-link>
      </template>
      <template v-else>
        <cell-value :col="col" :object="object"></cell-value>
      </template>
    </td>
    <td class="col-action" v-if="showActions">
      <div v-if="actionDropdown">
        <div ref="toggleTooltip" role="button" class="cursor-pointer" aria-describedby="tooltip" @click="openPanel">
          <fa-icon :icon="['fas', 'ellipsis-h']"></fa-icon>
        </div>
        <div v-if="isDropDownOpen" class="card" ref="tooltip" role="tooltip" v-click-outside="closePanel">
          <div ref="tooltip" id="tooltip" role="tooltip" class="card-body px-0 py-1">
            <nav class="nav flex-column dropdown-nav">
              <span v-for="(action, key) in rowActions" :key="key">
                <a class="nav-link" href="" @click.prevent="handleAction(action.event, object)" :key="action.event">
                  {{ action.label }}
                </a>
              </span>
            </nav>
          </div>
        </div>
      </div>
      <div v-else>
        <a href="" class="action-link" @click.prevent="onTrigger(object)">
          <fa-icon :icon="['fas', 'ellipsis-h']"></fa-icon>
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import { createPopper } from '@popperjs/core/lib/popper-lite.js'
import { concat, orderBy } from 'lodash-es'
import CellValue from './CellValue'

export default {
  components: {
    CellValue
  },
  data () {
    return {
      isDropDownOpen: false,
      actionList: [
        {
          label: 'Editer',
          icon: 'pencil',
          event: 'edit',
          index: 10
        },
        {
          label: 'Supprimer',
          icon: 'trash',
          event: 'delete',
          index: 20
        }
      ]
    }
  },
  computed: {
    rowActions () {
      const actions = this.defaultActions === true
        ? concat([], this.actionList, this.customActions) : this.customActions

      return orderBy(actions, 'index', 'asc')
    }
  },
  props: {
    cols: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    object: {
      type: Object,
      required: true
    },
    showActions: {
      type: Boolean,
      required: false,
      default: true
    },
    onSelect: {
      type: Function,
      required: false,
      default: () => null
    },
    onEdit: {
      type: Function,
      required: false,
      default: () => null
    },
    onDelete: {
      type: Function,
      required: false,
      default: () => null
    },
    onTrigger: {
      type: Function,
      required: false,
      default: () => null
    },
    actionDropdown: {
      type: Boolean,
      required: false,
      default: true
    },
    customActions: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    defaultActions: {
      type: Boolean,
      required: false,
      default: true
    },
    link: {
      type: [String, Object]
    }
  },
  methods: {
    handleAction (eventName, object) {
      this.$emit('action', eventName, object)
    },
    openPanel () {
      this.isDropDownOpen = !this.isDropDownOpen
      this.$nextTick(() => {
        if (this.$refs.toggleTooltip && this.$refs.tooltip) {
          createPopper(this.$refs.toggleTooltip, this.$refs.tooltip, {
            placement: 'bottom-end'
          })
        }
      })
    },
    closePanel () {
      this.isDropDownOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.cell-link {
  width: 100%;
  color: #212529;
  display: block;
  text-decoration: none;
  padding: 0.75rem;
}
</style>
