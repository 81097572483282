import Vue from 'vue'
import { ActionContext } from 'vuex'
import { nanoid } from 'nanoid'
import { cloneDeep, find, pick, omit } from 'lodash-es'
import { ProductEditorState, ProductPhoto, ProductVariant, ProductVariantPhotos, StatusEnum, WithKey } from '@/store/types'
import http from '@/utils/http'
import { mapDelete } from '@/store/utils'

const getInitialState = (): ProductEditorState => ({
  isReady: false,
  productType: null,
  id: null,
  name: null,
  subtitle: null,
  shortDescription: null,
  description: null,
  categoryId: null,
  defaultPrice: null,
  defaultRegularPrice: null,
  slug: '',
  status: StatusEnum.DRAFT,
  isSuspended: false,
  options: [],
  taxes: [],
  variants: [],
  initialVariants: [],
  productPhotos: [],
  initialPhotos: []
})

const getVariant = (): ProductVariant => ({
  id: null,
  sku: null,
  inventoryMode: 'status',
  inventoryStatus: 'in_stock',
  inventoryQuantity: null,
  allowBackorders: null,
  lowStockThreshold: null,
  limitPerOrder: null,
  taxable: null,
  pricePolicy: 'default_price',
  price: null,
  regularPrice: null,
  finalSale: null,
  options: {},
  length: null,
  width: null,
  height: null,
  weight: null,
  productVariantPhotos: []
})

type LocalActionContext = ActionContext<ProductEditorState, any>

const mapProductPayload = (state: ProductEditorState): Record<string, unknown> => {
  return {
    ...pick(state, ['name', 'subtitle', 'shortDescription', 'description', 'categoryId', 'options', 'taxes', 'defaultPrice', 'defaultRegularPrice', 'status']),
    type: state.productType
  }
}

const mapVariantPayload = (productId: string, variant: WithKey<ProductVariant>): Record<string, unknown> => {
  return {
    ...omit(variant, 'id'),
    productId
  }
}

const mapPhotoPayload = (productId: string, photo: WithKey<ProductPhoto>): Record<string, unknown> => {
  return {
    ...omit(photo, 'id'),
    productId
  }
}

const mapProductVariantPhotoPayload = (
  productVariantPhotos: (ProductVariantPhotos & { variantKey?: string })[],
  variants: WithKey<ProductVariant>[]
): ProductVariantPhotos[] => {
  return productVariantPhotos.map((vp) => {
    const existingVariant = variants.find(variant => variant.key === vp.variantKey)
    return { ...omit(vp, 'variantKey'), variantId: existingVariant?.id || null }
  })
}

export default {
  namespaced: true,

  state: {
    ...getInitialState()
  },

  getters: {},

  mutations: {
    RESET_STATE: (state: ProductEditorState) => {
      Object.assign(state, getInitialState())
    },

    SET_IS_READY: (state: ProductEditorState, isReady: boolean) => {
      state.isReady = isReady
    },

    SET_VALUES: (state: ProductEditorState, values: Partial<ProductEditorState>) => {
      for (const key in values) {
        // @ts-ignore
        Vue.set(state, key, values[key])
      }
    },

    SET_VARIANTS: (state: ProductEditorState, variants: Array<WithKey<ProductVariant>>) => {
      state.variants = variants
    },

    SET_INIT_VARIANTS: (state: ProductEditorState, variants: Array<WithKey<ProductVariant>>) => {
      state.initialVariants = variants
    },

    SET_UNIQUE_VARIANT_VALUES: (state: ProductEditorState, values: Partial<ProductVariant>) => {
      const variant = { ...state.variants[0] }

      for (const key in values) {
        // @ts-ignore
        variant[key] = values[key]
      }

      state.variants.splice(0, 1, variant)
    },

    SET_VARIANT_VALUES: (state: ProductEditorState, values: WithKey<ProductVariant>) => {
      const variant = find(state.variants, { key: values.key })
      const index = variant ? state.variants.indexOf(variant) : -1

      if (variant && index > -1) {
        state.variants.splice(index, 1, {
          ...variant,
          ...values
        })
      }
    },

    ADD_VARIANT: (state: ProductEditorState, { variant, productPhotos }: { variant: Partial<ProductVariant>; productPhotos: WithKey<ProductPhoto>[]}) => {
      const key = nanoid()

      state.variants.push({
        ...getVariant(),
        key,
        ...variant
      })

      if (!state.productPhotos) {
        return
      }

      // add new generated variant key to the new product variant photo if needed
      state.productPhotos = productPhotos.map(photo => {
        if (!photo.productVariantPhotos || !photo.productVariantPhotos.length) {
          return { ...photo }
        }

        return {
          ...photo,
          productVariantPhotos: photo.productVariantPhotos.map(vp => {
            // locate the product variant photo that has all nulls but is set as default
            if (vp.isDefault && !vp.variantKey && !vp.variantId) {
              return { ...vp, variantKey: key }
            }

            return { ...vp }
          })
        }
      })
    },

    REMOVE_VARIANT: (state: ProductEditorState, id: string) => {
      state.variants = state.variants.filter(variant => variant.id !== id)
    },

    SET_PRODUCT_ID: (state: ProductEditorState, id: string) => {
      state.id = id
    },

    SET_VARIANT_ID: (state: ProductEditorState, { key, id, sku }: { key: string; id: string; sku: string }) => {
      const variant = find(state.variants, { key })
      const index = variant ? state.variants.indexOf(variant) : -1

      if (variant && index > -1) {
        state.variants.splice(index, 1, {
          ...variant,
          id,
          sku
        })
      }
    },

    SET_PHOTO_ID: (state: ProductEditorState, { key, id }: { key: string; id: string }) => {
      const photo = find(state.productPhotos, { key })
      const index = photo ? state.productPhotos.indexOf(photo) : -1

      if (photo && index > -1) {
        state.productPhotos.splice(index, 1, {
          ...photo,
          id
        })
      }
    },

    SET_INIT_PHOTOS: (state: ProductEditorState, photos: Array<WithKey<ProductPhoto>>) => {
      state.initialPhotos = photos
    },

    UPDATE_PRODUCT_PHOTOS: (state: ProductEditorState, photos: Array<WithKey<ProductPhoto>>) => {
      state.productPhotos = photos.map(photo => ({ ...photo, key: !photo.key ? nanoid() : photo.key }))
    },

    PRODUCT_FETCHED: (state: ProductEditorState, product: Record<string, any>) => {
      state.id = product.id
      state.productType = product.type
      state.name = product.name
      state.subtitle = product.subtitle
      state.shortDescription = product.shortDescription
      state.description = product.description
      state.categoryId = product.categoryId
      state.defaultPrice = Number(product.defaultPrice)
      state.defaultRegularPrice = Number(product.defaultRegularPrice)
      state.variants = []
      state.options = product.options
      state.taxes = product.taxes
      state.status = product.status
      state.productPhotos = product.productPhotos
      state.slug = product.slug
      state.isSuspended = product.isSuspended

      for (const variant of product.variants) {
        state.variants.push({
          ...getVariant(),
          key: nanoid(),
          id: variant.id,
          sku: variant.sku,
          inventoryMode: variant.inventoryMode,
          inventoryStatus: variant.inventoryStatus,
          inventoryQuantity: variant.inventoryQuantity,
          allowBackorders: variant.allowBackorders,
          lowStockThreshold: variant.lowStockThreshold,
          limitPerOrder: variant.limitPerOrder,
          taxable: variant.taxable,
          pricePolicy: variant.pricePolicy,
          price: variant.price,
          regularPrice: variant.regularPrice,
          finalSale: variant.finalSale,
          options: variant.options,
          length: variant.length,
          width: variant.width,
          height: variant.height,
          weight: variant.weight,
          productVariantPhotos: variant.productVariantPhotos
        })
      }
    },

    SET_STATUS: (state: ProductEditorState, status: StatusEnum) => {
      state.status = status
    }
  },

  actions: {
    async initCreateForm ({ commit, dispatch }: ActionContext<ProductEditorState, any>, type: 'simple' | 'variable') {
      commit('RESET_STATE')

      commit('SET_VALUES', {
        productType: type
      })

      if (type === 'simple') {
        commit('SET_VARIANTS', [
          {
            ...getVariant(),
            key: nanoid()
          }
        ])
      }

      await dispatch('fetchAndSetDefaultTaxes')

      commit('SET_IS_READY', true)
    },

    async initEditForm ({ commit }: LocalActionContext, id: string) {
      commit('SET_IS_READY', false)

      const { data: product } = await http.get(`/v1/products/${id}`)
      commit('PRODUCT_FETCHED', product)
      commit('SET_INIT_VARIANTS', cloneDeep(product.variants))
      commit('SET_INIT_PHOTOS', cloneDeep(product.productPhotos))

      commit('SET_IS_READY', true)
    },

    async fetchAndSetDefaultTaxes ({ rootState, dispatch }: LocalActionContext) {
      if (!rootState.taxRates.isFetched) {
        await dispatch('taxRates/fetch', rootState.auth.currentStoreId, { root: true })
      }

      await dispatch('setDefaultApplicableTaxes')
    },

    async setDefaultApplicableTaxes ({ rootGetters, commit }: LocalActionContext) {
      const availableTaxes = rootGetters['taxRates/getAvailableTaxes']
      commit('SET_VALUES', {
        taxes: availableTaxes.map((tax: Record<string, string>) => tax.code)
      })
    },

    async save ({ dispatch }: LocalActionContext, { storeId }: { storeId: string }) {
      try {
        await dispatch('saveProduct', { storeId })
        await dispatch('saveVariants')
        await dispatch('savePhotos', { storeId })

        Vue.toasted.success('Produit sauvegardé avec succès.')
      } catch (e) {
        Vue.toasted.error("Une erreur s'est produite. Veuillez réessayer.")
        throw e
      }
    },

    async saveProduct ({ state, commit }: LocalActionContext, { storeId }: { storeId: string }) {
      try {
        if (!state.id) {
          const { data: savedProduct } = await http.post(`/v1/stores/${storeId}/products`, mapProductPayload(state))
          commit('SET_PRODUCT_ID', savedProduct.id)
        } else {
          await http.put(`/v1/stores/${storeId}/products/${state.id}`, mapProductPayload(state))
        }
      } catch (e) {
        console.error('API ERROR', e)
        throw e
      }
    },

    async saveVariants ({ state, commit }: LocalActionContext) {
      if (!state.id) {
        console.error('Error, product Id is not set')
        return
      }

      try {
        for (const variant of state.variants) {
          if (!variant.id) {
            const { data: savedVariant } = await http.post('/v1/product-variants', mapVariantPayload(state.id, variant))

            commit('SET_VARIANT_ID', {
              key: variant.key,
              id: savedVariant.id,
              sku: savedVariant.sku
            })
          } else {
            await http.put(`/v1/product-variants/${variant.id}`, mapVariantPayload(state.id, variant))
          }
        }

        await mapDelete<ProductVariant>(state.initialVariants, state.variants, '/v1/product-variants/')
        commit('SET_INIT_VARIANTS', cloneDeep(state.variants))
      } catch (e) {
        console.error('API ERROR', e)
        throw e
      }
    },

    async savePhotos ({ state, commit }: LocalActionContext, { storeId }: { storeId: string }) {
      if (!state.id) {
        console.error('Error, product Id is not set')
        return
      }

      try {
        for (const photo of state.productPhotos) {
          if (!photo.id) {
            const productVariantPhotos = photo.productVariantPhotos && photo.productVariantPhotos.length > 0
              ? mapProductVariantPhotoPayload(photo.productVariantPhotos, state.variants)
              : photo.productVariantPhotos

            const { data: savedPhoto } =
              await http.post(`/v1/stores/${storeId}/product-photos`, mapPhotoPayload(state.id, { ...photo, productVariantPhotos }))

            commit('SET_PHOTO_ID', {
              key: photo.key,
              id: savedPhoto.id
            })
          } else {
            await http.put(`/v1/stores/${storeId}/product-photos/${photo.id}`, mapPhotoPayload(state.id, photo))
          }
        }

        await mapDelete<ProductPhoto>(state.initialPhotos, state.productPhotos, `/v1/stores/${storeId}/product-photos`)
        commit('SET_INIT_PHOTOS', cloneDeep(state.productPhotos))
      } catch (e) {
        console.error('API ERROR', e)
        throw e
      }
    }
  }
}
