import Vue from 'vue'

export default Vue.extend({
  computed: {
    isUserAuthenticated () {
      return this.$store.state.auth.authenticated
    },
    currentUser () {
      return this.$store.state.auth.user
    },
    userId () {
      // @ts-ignore
      return this.currentUser ? this.currentUser.id : null
    },
    currentStoreId () {
      return this.$store.state.auth.currentStoreId
    },
    currentStore () {
      return this.$store.getters.getCurrentStore
    }
  }
})
