export interface IQueryParams {
  page: number;
  perPage: number;
  status?: string;
  text?: string;
  type?: string;
  sortBy?: string;
  sortDirection?: string;
}

export interface IParams {
  page: number | null;
  storeId: string;
}

export interface IState {
  page: number;
  perPage: number;
  statusFilter?: string;
  textFilter?: string;
  typeFilter?: string;
  sortBy?: string;
  sortDirection?: string;
}

export const getQueryParams = (params: IParams, state: IState): IQueryParams => {
  const queryParams: IQueryParams = {
    page: !params.page ? state.page : params.page,
    perPage: state.perPage
  }

  if (state.statusFilter) {
    queryParams.status = state.statusFilter
  }

  if (state.textFilter) {
    queryParams.text = state.textFilter
  }

  if (state.sortBy) {
    queryParams.sortBy = state.sortBy
  }

  if (state.sortDirection) {
    queryParams.sortDirection = state.sortDirection
  }

  if (state.typeFilter) {
    queryParams.type = state.typeFilter
  }

  return queryParams
}
