<template>
  <div class="dropdown-container" :class="[alignClass]">
    <slot name="button" v-bind:open="openPanel"></slot>

    <transition :name="effect">
      <div class="dropdown-panel" v-if="isOpen" @click="closePanel" v-click-outside="closePanel">
        <slot name="panel"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: 'left'
    },
    effect: {
      type: String,
      default: 'fadeDown',
      required: false
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    alignClass () {
      return this.align ? `dropdown-align-${this.align}` : null
    }
  },
  methods: {
    openPanel () {
      this.isOpen = true
    },
    closePanel () {
      this.isOpen = false
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-container {
  position: relative;

  .dropdown-panel {
    position: absolute;
    top: 100%;
    z-index: 100;
    margin-top: 5px;
    min-width: 160px;
  }

  &.dropdown-align-right {
    .dropdown-panel {
      right: 0;
    }
  }
}
</style>
