<template>
  <div id="app" class="ove-app">
    <template v-if="isReady">
      <router-view/>
    </template>

    <template v-else>
      <div class="loading-screen">
        <b-spinner></b-spinner>
      </div>
    </template>
  </div>
</template>

<script>
import firebase from '@/firebase'
import userData from '@/mixins/user-data'

export default {
  mixins: [userData],
  data () {
    return {
      isReady: false
    }
  },
  methods: {
    async initWebApp () {
      await this.authenticateUser()
      await this.fetchData()
    },
    authenticateUser () {
      return new Promise((resolve) => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            this.$store.commit('USER_AUTHENTICATED', user.uid)
          } else {
            // console.log('User is signed out', user)
          }

          resolve()
        })
      })
    },
    async fetchData () {
      this.isReady = false

      if (this.isUserAuthenticated) {
        if (!this.$store.state.auth.userFetched) {
          const user = await this.$store.dispatch('fetchCurrentUser')
          const stores = await this.$store.dispatch('fetchUserStores')

          if (!(user && stores && stores.length > 0)) {
            await this.$store.dispatch('logout')
          }
        }
      }

      this.isReady = true
    }
  },
  created () {
    this.initWebApp()
  },
  watch: {
    isUserAuthenticated (bool) {
      if (bool === true) {
        this.fetchData()
      } else {
        // this.detachStore()
      }
    }
  }
}
</script>

<style lang="scss">
@import './assets/styles/app';

#app {
  font-family: Nunito, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 0.9rem;
}

.loading-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-decoration-none {
  text-transform: none;
}
</style>
