import { cloneDeep } from 'lodash-es'
import { createPaginatedStore } from '@/utils/store'
import http from '@/utils/http'
import { getQueryParams, IQueryParams } from '@/utils/query-params'
import { sortState, mutations } from '@/utils/store-pagination'

const store = createPaginatedStore({
  fetchById: async ({ id, storeId }) => {
    const { data: products } = await http.get(`/v1/stores/${storeId}/products/${id}`)

    return products
  },

  delete: async ({ storeId, id }: { storeId: string; id: string }) => {
    await http.delete(`/v1/stores/${storeId}/products/${id}`)
  }
}, {
  state: {
    textFilter: '',
    typeFilter: '',
    statusFilter: '',
    ...sortState
  },

  mutations,

  actions: {
    fetch: async ({ commit, state }: any, data: { page: number | null; storeId: string }) => {
      commit('START_FETCHING')

      const params: IQueryParams = getQueryParams(data, state)

      commit('SET_PAGE', params.page)

      const { data: products } = await http.get(`/v1/stores/${data.storeId}/products`, {
        params
      })

      commit('FETCH_SUCCESS', products)

      return products
    },

    updateStatus: async ({ commit, dispatch, state }: any, data: { id: string; storeId: string; status: string }) => {
      commit('START_FETCHING')

      await http.patch(`/v1/stores/${data.storeId}/products/${data.id}/status`, { status: data.status })

      await dispatch('fetch', { page: state.page, storeId: data.storeId })
    }
  }
})

export default {
  ...store,
  state: () => {
    return cloneDeep(store.state)
  }
}
