import { WithKey } from './common'
import { File } from '@/store/types/files'

export interface ProductEditorState {
  isReady: boolean;
  productType: 'simple' | 'variable' | null;
  id: string | null;
  name: string | null;
  subtitle: string | null;
  shortDescription: string | null;
  description: string | null;
  categoryId: string | null;
  defaultPrice: number | null;
  defaultRegularPrice: number | null;
  slug: string;
  options: string[];
  taxes: string[];
  status: StatusEnum;
  isSuspended: boolean;
  variants: Array<WithKey<ProductVariant>>;
  initialVariants: Array<ProductVariant>;
  productPhotos: Array<WithKey<ProductPhoto>>;
  initialPhotos: Array<ProductPhoto>;
}

export enum StatusEnum {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export interface ProductVariant {
  id: string | null;
  sku: string | null;
  inventoryMode: 'status' | 'stock' | null;
  inventoryStatus: 'in_stock' | 'out_of_stock' | null;
  inventoryQuantity: number | null;
  allowBackorders: boolean | null;
  lowStockThreshold: number | null;
  limitPerOrder: number | null;
  taxable: boolean | null;
  pricePolicy: 'default_price' | 'custom_price' | null;
  price: number | null;
  regularPrice: number | null;
  finalSale: boolean | null;
  options: Record<string, string>;
  length: number | null;
  width: number | null;
  height: number | null;
  weight: number | null;
  productVariantPhotos: ProductVariantPhotos[] | null;
}

export interface ProductPhoto {
  id: string | null;
  storeId: string | null;
  productId: string | null;
  fileId: string | null;
  isGlobal: boolean | null;
  rank: number | null;
  file: File;
  productVariantPhotos: (ProductVariantPhotos & { variantKey?: string })[] | null;
}

export interface ProductVariantPhotos {
  productPhotoId: string | null;
  variantId: string | null;
  isDefault: boolean | null;
}
