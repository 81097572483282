/* eslint-disable import/first */

/**
 * Initialization file to load additional resources needed by the application
 */

import Vue from 'vue'

// Modal plugin

import { ModalPlugin } from '@/modules/modal'
Vue.use(ModalPlugin)

// Forms plugin

import { FormsPlugin } from '@/modules/forms'
Vue.use(FormsPlugin)

// Zoom-image plugin
import { ZoomImagePlugin } from '@/modules/zoom-image'

Vue.use(ZoomImagePlugin)

Vue.forms.registerTypes([
  {
    name: 'carrier-selector',
    input: () => import('@/components/forms/inputs/CarrierSelector.vue')
  },
  {
    name: 'date-picker',
    input: () => import('@/components/forms/inputs/DatePicker.vue')
  },
  {
    name: 'multi-text',
    input: () => import('@/components/forms/inputs/MultiText.vue')
  },
  {
    name: 'taxes-selector',
    input: () => import('@/components/forms/inputs/TaxesSelector.vue')
  },
  {
    name: 'activity-selector',
    input: () => import('@/components/forms/inputs/StoreActivitySelector.vue')
  },
  {
    name: 'product-category-selector',
    input: () => import('@/components/forms/inputs/ProductCategorySelector.vue')
  },
  {
    name: 'html-editor',
    input: () => import('@/components/forms/inputs/HtmlEditor.vue')
  }
])

// Bootstrap Vue

import { SpinnerPlugin, DropdownPlugin, FormCheckboxPlugin, TabsPlugin, FormSelectPlugin, TooltipPlugin } from 'bootstrap-vue'
// Vue.use(ModalPlugin)
Vue.use(SpinnerPlugin)
Vue.use(DropdownPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(TabsPlugin)
Vue.use(FormSelectPlugin)
Vue.use(TooltipPlugin)

// V-Tooltip

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

// Click Outside Directive

// @ts-ignore
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

// Vue Toasted

import Toasted from 'vue-toasted'
Vue.use(Toasted, {
  duration: 3000,
  position: 'top-center'
})

// V-Calendar

// @ts-ignore (There is currently no supported types for this package)
import VCalendar from 'v-calendar'
Vue.use(VCalendar)

// Load Font Awesome

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('fa-icon', FontAwesomeIcon)

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBars,
  faEllipsisH,
  faEllipsisV,
  faChevronLeft,
  faFilter,
  faExclamationTriangle,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faPlus,
  faPen,
  faTimes,
  faInfoCircle,
  faTrash,
  faCog,
  faUpload,
  faExternalLinkAlt,
  faExclamationCircle,
  faCloudDownloadAlt,
  faSearch,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons'

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'

library.add(
  faBars,
  faEllipsisH,
  faEllipsisV,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faFilter,
  faPlus,
  faPen,
  faTimes,
  faInfoCircle,
  faTrash,
  faCog,
  faUpload,
  faExternalLinkAlt,
  faQuestionCircle,
  faExclamationCircle,
  faCloudDownloadAlt,
  faSearch,
  faArrowLeft
)

// Handle user JWT authentication

/*
import store from './store'
import { isAuthenticated } from '@/utils/auth-utils'

if (isAuthenticated()) {
  store.commit('USER_AUTHENTICATED')
  store.commit('SET_CURRENT_ACCOUNT_ID', localStorage.getItem('workspace_id'))
}
*/

/**
 * wysiwyg editor
 */
import CKEditor from '@ckeditor/ckeditor5-vue2'
Vue.use(CKEditor)
