import { filter, find, assign, omit } from 'lodash-es'
import { spliceCollection, triggerMethod } from './helpers'
import {
  ICollectionStoreMethods,
  ICustomStore,
  GenericObject,
  WithId,
  IPaginatedState,
  IPaginatedData
} from './types'

const defaultCustomStore = {
  modules: {},
  state: {},
  getters: {},
  mutations: {},
  actions: {}
}

export const createPaginatedStore = (methods: ICollectionStoreMethods, customStoreConfig: ICustomStore = {}) => {
  const customStore = {
    ...defaultCustomStore,
    ...customStoreConfig
  }

  const state: IPaginatedState = {
    perPage: 20,
    page: 1,
    paginatedData: {
      total: 0,
      currentPage: 1,
      pageCount: 1,
      perPage: 1,
      from: 1,
      to: 1,
      data: []
    },
    isFetched: false,
    isFetching: false,
    isRefreshing: false,
    itemsById: {},
    ...customStore.state
  }

  const getters = {
    /**
     * Get item summary from collection query
     */
    getById: (state: IPaginatedState) => (id: string) => {
      return find(state.paginatedData.data, { id })
    },
    /**
     * Get item details from individual query
     */
    getDetailsById: (state: IPaginatedState) => (id: string) => {
      return state.itemsById[id] || null
    },
    ...customStore.getters
  }

  const actions = {}

  const mutations = {
    RESET_STATE: (state: IPaginatedState) => {
      Object.assign(state, {
        perPage: 20,
        page: 1,
        paginatedData: {
          total: 0,
          currentPage: 1,
          pageCount: 1,
          perPage: 1,
          from: 1,
          to: 1,
          data: []
        },
        isFetched: false,
        isFetching: false,
        isRefreshing: false,
        itemsById: {},
        ...customStore.state
      })
    },

    START_FETCHING: (state: IPaginatedState) => {
      state.isFetching = true
      state.isFetched = false
    },

    FETCH_SUCCESS: (state: IPaginatedState, paginatedData: IPaginatedData) => {
      state.paginatedData = paginatedData
      state.isFetching = false
      state.isFetched = true
    },

    FETCH_FAILURE: (state: IPaginatedState) => {
      state.isFetching = false
    },

    REFRESH_STARTED: (state: IPaginatedState) => {
      state.isRefreshing = true
    },

    START_REFRESHING: (state: IPaginatedState) => {
      state.isRefreshing = true
    },

    REFRESH_SUCCESS: (state: IPaginatedState, paginatedData: IPaginatedData) => {
      state.paginatedData = paginatedData
      state.isRefreshing = false
    },

    REFRESH_FAILURE: (state: IPaginatedState) => {
      state.isRefreshing = false
    },

    ITEM_FETCHED: (state: IPaginatedState, item: WithId<GenericObject>) => {
      state.itemsById = {
        ...state.itemsById,
        [item.id]: item
      }
    },

    ITEM_CREATED: (state: IPaginatedState, item: WithId<GenericObject>) => {
      state.paginatedData.data.push(item)

      state.itemsById = {
        ...state.itemsById,
        [item.id]: item
      }
    },

    ITEM_UPDATED: (state: IPaginatedState, item: WithId<GenericObject>) => {
      spliceCollection(state.paginatedData.data, item.id, item)

      state.itemsById = {
        ...state.itemsById,
        [item.id]: item
      }
    },

    ITEM_DELETED: (state: IPaginatedState, id: string) => {
      state.paginatedData.data = filter(state.paginatedData.data, item => item.id !== id)
      state.itemsById = omit(state.itemsById, id)
    },

    SET_PAGE: (state: IPaginatedState, page: string | number) => {
      state.page = Number(page)
    },

    SET_PER_PAGE: (state: IPaginatedState, perPage: string | number) => {
      state.perPage = Number(perPage)
    },

    ...customStore.mutations
  }

  if (methods && methods.fetch) {
    assign(actions, {
      fetch: async ({ commit, state }: any, data: { page: number | null }) => {
        commit('START_FETCHING')

        const params = {
          page: !data.page ? state.page : data.page,
          perPage: state.perPage
        }

        commit('SET_PAGE', params.page)

        try {
          const paginatedData = await triggerMethod(methods, 'fetch', params)
          commit('FETCH_SUCCESS', paginatedData)
          return paginatedData
        } catch (e) {
          commit('FETCH_FAILURE')
          throw e
        }
      }
    })

    assign(actions, {
      refresh: async ({ commit }: any, data: any) => {
        commit('START_REFRESHING')

        try {
          const paginatedData = await triggerMethod(methods, 'fetch', data)
          commit('REFRESH_SUCCESS', paginatedData)
          return paginatedData
        } catch (e) {
          commit('REFRESH_FAILURE')
          throw e
        }
      }
    })

    assign(actions, {
      fetchOrRefresh: ({ state, dispatch }: any, data: any = undefined) => {
        if (state.isFetched) {
          return dispatch('refresh', data)
        } else {
          return dispatch('fetch', data)
        }
      }
    })
  }

  if (methods && methods.fetchById) {
    assign(actions, {
      fetchById: async ({ commit }: any, payload: any) => {
        const item = await triggerMethod(methods, 'fetchById', payload)
        commit('ITEM_FETCHED', item)
        return item
      }
    })
  }

  if (methods && methods.create) {
    assign(actions, {
      create: async ({ commit }: any, data: object) => {
        const object = await triggerMethod(methods, 'create', data)
        commit('ITEM_CREATED', object)
        return object
      }
    })
  }

  if (methods && methods.update) {
    assign(actions, {
      update: async ({ commit }: any, data: object) => {
        const object = await triggerMethod(methods, 'update', data)

        if (object) {
          commit('ITEM_UPDATED', object)
          return object
        }
      }
    })
  }

  if (methods && methods.delete) {
    assign(actions, {
      delete: async ({ commit }: any, payload: any) => {
        const id = typeof payload === 'object' ? payload.id : payload
        await triggerMethod(methods, 'delete', payload)
        commit('ITEM_DELETED', id)
      }
    })
  }

  assign(actions, customStore.actions)

  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
      ...customStore.modules
    }
  }
}
