<template>
  <div class="fixed-sidebar">
    <div class="sidebar-inner">
      <div class="brand p-4 mb-3">
        <router-link to="/">
          <img width="200" src="@/assets/images/logo/inline-logo-le-bon-panier-white.svg" alt="Le bon panier.ca">
        </router-link>
      </div>
      <navigation />
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/nav/Navigation'
export default {
  components: {
    Navigation
  },
  computed: {
    //
  }
}
</script>

<style scoped lang="scss">
.fixed-sidebar {
  position: fixed;
  top: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 270px;
  background: #010e1f;
  color: rgba(255, 255, 255, 0.7);

  z-index: 50;

  .sidebar-inner {
    overflow-y: auto;
  }
}

.brand,
.brand a {
  font-size: 20px;
  color: #f8f9fa;
  font-weight: 600;
  user-select: none;
  text-decoration: none;
}
</style>
