import { trimEnd } from 'lodash-es'

export default {
  env: process.env.VUE_APP_ENV || 'local',
  auth: {
    userKeyName: 'store_user_id',

    guestRedirectPath: '/login',
    memberRedirectPath: '/'
  },
  api: {
    baseUrl: process.env.VUE_APP_API_URL || ''
  },
  app: {
    frontUrl: process.env.VUE_APP_FRONT_URL || ''
  },
  firebase: {
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MSG_SENDER_ID
  },
  storage: {
    endpoint: process.env.VUE_APP_STORAGE_ENDPOINT,
    cdnUrl: trimEnd(process.env.VUE_APP_STORAGE_CDN_URL, '/')
  },
  shopify: {
    apiKey: process.env.VUE_APP_SHOPIFY_API_KEY || ''
  }
}
