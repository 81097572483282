import { createCollectionStore } from '@/utils/store'
import http from '@/utils/http'

export default createCollectionStore({
  fetch: async (storeId: string) => {
    const { data: variantTypes } = await http.get(`/v1/stores/${storeId}/variant-types`)
    return variantTypes
  },

  create: async ({ storeId, ...payload }: Record<string, any>) => {
    const { data: variantType } = await http.post(`/v1/stores/${storeId}/variant-types`, payload)
    return variantType
  }
})
