import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import config from '@/config'
import mainRoutes from './main.routes'
import connectRoutes from './connect.routes'
import store from '../store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'auth.login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Login.vue'),
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/inscription',
    name: 'auth.register',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Register.vue'),
    meta: {
      requiresGuest: true
    }
  },
  ...connectRoutes,
  /**
   * Except special cases, all application routes must be nested here
   */
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ '@/views/AppRouter.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      ...mainRoutes
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('CLOSE_MENU')

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const userId = localStorage.getItem(config.auth.userKeyName)

    if (!userId) {
      // const query = to.fullPath !== '/' ? { redirect: to.fullPath } : null
      next({
        // query,
        path: config.auth.guestRedirectPath
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresGuest)) {
    const userId = localStorage.getItem(config.auth.userKeyName)

    if (userId) {
      next({
        path: config.auth.memberRedirectPath
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
