import { find } from 'lodash-es'
import { GenericObject, ICollectionStoreMethods, INestedCollectionStoreMethods } from './types'

export const spliceCollection = (collection: Array<GenericObject>, id: string, replaceBy: GenericObject | null = null) => {
  const object = find(collection, { id })
  const index = object ? collection.indexOf(object) : null

  if (index && index > -1) {
    if (replaceBy) {
      collection.splice(index, 1, replaceBy)
    } else {
      collection.splice(index, 1)
    }
  }
}

export const triggerMethod = async (methods: ICollectionStoreMethods | INestedCollectionStoreMethods, name: 'fetch'|'fetchById'|'create'|'update'|'delete', data: any = null) => {
  // @ts-ignore
  if (methods && name && methods[name] && typeof methods[name] === 'function') {
    // @ts-ignore
    return methods[name](data)
  }
  return null
}
