import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import creditNotes from './modules/creditNotes'
import invoices from './modules/invoices'
import orders from './modules/orders'
import pages from './modules/pages'
import productCategories from './modules/productCategories'
import productEditor from './modules/productEditor'
import products from './modules/products'
import profile from './modules/profile'
import publicProfile from './modules/publicProfile'
import returnRequests from './modules/return-requests'
import shipments from './modules/shipments'
import supportRequests from './modules/supportRequests'
import taxRates from './modules/taxRates'
import transfers from './modules/transfers'
import variantTypes from './modules/variantTypes'

Vue.use(Vuex)

const resettableStores = [
  'creditNotes',
  'invoices',
  'orders',
  'pages',
  'productCategories',
  'productEditor',
  'products',
  'profile',
  'publicProfile',
  'returnRequests',
  'shipments',
  'supportRequests',
  'taxRates',
  'transfers',
  'variantTypes'
]

export default new Vuex.Store({
  state: {
    isMenuOpen: false
  },
  mutations: {
    TOGGLE_MENU (state) {
      state.isMenuOpen = !state.isMenuOpen
    },
    CLOSE_MENU (state) {
      state.isMenuOpen = false
    }
  },
  actions: {
    resetAll ({ commit }: any) {
      for (const store of resettableStores) {
        commit(`${store}/RESET_STATE`)
      }
    }
  },
  modules: {
    orders,
    auth,
    creditNotes,
    invoices,
    pages,
    productCategories,
    productEditor,
    products,
    profile,
    publicProfile,
    returnRequests,
    shipments,
    supportRequests,
    taxRates,
    transfers,
    variantTypes
  }
})
