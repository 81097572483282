import { cloneDeep } from 'lodash-es'
import { createPaginatedStore } from '@/utils/store'
import http from '@/utils/http'
import { getQueryParams, IQueryParams } from '@/utils/query-params'
import { sortState, mutations } from '@/utils/store-pagination'

const store = createPaginatedStore({
  fetchById: async ({ id }) => {
    const { data: supportRequest } = await http.get(`/v1/support/requests/${id}`)

    return supportRequest
  }
}, {
  state: {
    textFilter: '',
    statusFilter: '',
    ...sortState
  },

  mutations,

  actions: {
    fetch: async ({ commit, state }: any, data: { page: number | null; storeId: string; userId: string }) => {
      commit('START_FETCHING')

      const params: IQueryParams = getQueryParams(data, state)

      commit('SET_PAGE', params.page)

      const { data: supportRequests } = await http.get('/v1/support/requests', {
        params: {
          storeId: data.storeId,
          userId: data.userId,
          ...params
        }
      })

      commit('FETCH_SUCCESS', supportRequests)

      return supportRequests
    }
  }
})

export default {
  ...store,
  state: () => {
    return cloneDeep(store.state)
  }
}
