export const getErrorType = (e: any): string => {
  const httpStatus: number | null = e.response && e.response.status ? e.response.status : null

  const errors: Record<number, string> = {
    400: 'validation',
    401: 'unauthorized',
    402: 'payment',
    403: 'forbidden',
    404: 'not_found',
    422: 'validation'
  }

  if (httpStatus && errors[httpStatus]) {
    return errors[httpStatus]
  }

  return 'internal'
}
