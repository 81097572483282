import Vue, { VueConstructor } from 'vue'
import ModalSlot from './ModalSlot.vue'
import Modal from './Modal.vue'
import { ConfirmModalPayload, VueModalObject, FormModalPayload } from './types'

const vm = new Vue({
  data: {
    isVisible: false,
    component: '',
    data: {},
    onConfirm: null,
    onCancel: null
  }
})

const VueModal: VueModalObject = {
  state: vm.$data,
  options: {},

  async open (component: any, data = {}): Promise<any> {
    this.state.component = component
    this.state.data = data
    this.state.isVisible = true

    return new Promise((resolve, reject) => {
      this.state.onCancel = reject
      this.state.onConfirm = resolve
    })
  },

  openConfirmModal (payload: ConfirmModalPayload) {
    return this.open(() => import('./generic-modals/ConfirmModal.vue'), payload)
  },

  openFormModal (payload: FormModalPayload) {
    if (typeof Vue.forms === 'undefined') {
      throw new Error('Vue forms plugin is not installed.')
    }

    return this.open(() => import('./generic-modals/FormModal.vue'), payload)
  },

  close () {
    this.state.isVisible = false
    this.state.component = null
    this.state.data = {}
    this.state.onConfirm = null
    this.state.onCancel = null
  },

  triggerConfirm (data: any) {
    if (typeof this.state.onConfirm === 'function') {
      this.state.onConfirm(data)
    }
  },

  triggerCancel () {
    if (typeof this.state.onCancel === 'function') {
      this.state.onCancel('ACTION_CANCELLED')
    }
  }
}

export const ModalPlugin = {
  install: (Vue: VueConstructor) => {
    Vue.component('v-modal-slot', ModalSlot)
    Vue.component('v-modal', Modal)

    Vue.prototype.$modal = VueModal
    Vue.modal = VueModal
  }
}
