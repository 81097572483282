import { filter, find, assign, omit } from 'lodash-es'
import { spliceCollection, triggerMethod } from './helpers'
import {
  ICollectionStoreMethods,
  ICustomStore,
  ICollectionState,
  GenericObject,
  WithId
} from './types'

const defaultCustomStore = {
  modules: {},
  state: {},
  getters: {},
  mutations: {},
  actions: {}
}

export const createCollectionStore = (methods: ICollectionStoreMethods, customStoreConfig: ICustomStore = {}) => {
  const customStore = {
    ...defaultCustomStore,
    ...customStoreConfig
  }

  const state: ICollectionState = {
    collection: [],
    isFetched: false,
    isFetching: false,
    isRefreshing: false,
    itemsById: {},
    ...customStore.state
  }

  const getters = {
    /**
     * Get item summary from collection query
     */
    getById: (state: ICollectionState) => (id: string) => {
      return find(state.collection, { id })
    },
    /**
     * Get item details from individual query
     */
    getDetailsById: (state: ICollectionState) => (id: string) => {
      return state.itemsById[id] || null
    },
    ...customStore.getters
  }

  const actions = {}

  const mutations = {
    RESET_STATE: (state: ICollectionState) => {
      Object.assign(state, {
        collection: [],
        isFetched: false,
        isFetching: false,
        isRefreshing: false,
        itemsById: {},
        ...customStore.state
      })
    },

    START_FETCHING: (state: ICollectionState) => {
      state.isFetching = true
      state.isFetched = false
    },

    FETCH_SUCCESS: (state: ICollectionState, collection: Array<WithId<GenericObject>>) => {
      state.collection = collection
      state.isFetching = false
      state.isFetched = true
    },

    FETCH_FAILURE: (state: ICollectionState) => {
      state.isFetching = false
    },

    REFRESH_STARTED: (state: ICollectionState) => {
      state.isRefreshing = true
    },

    START_REFRESHING: (state: ICollectionState) => {
      state.isRefreshing = true
    },

    REFRESH_SUCCESS: (state: ICollectionState, collection: Array<WithId<GenericObject>>) => {
      state.collection = collection
      state.isRefreshing = false
    },

    REFRESH_FAILURE: (state: ICollectionState) => {
      state.isRefreshing = false
    },

    ITEM_FETCHED: (state: ICollectionState, item: WithId<GenericObject>) => {
      state.itemsById = {
        ...state.itemsById,
        [item.id]: item
      }
    },

    ITEM_CREATED: (state: ICollectionState, item: WithId<GenericObject>) => {
      state.collection.push(item)

      state.itemsById = {
        ...state.itemsById,
        [item.id]: item
      }
    },

    ITEM_UPDATED: (state: ICollectionState, item: WithId<GenericObject>) => {
      spliceCollection(state.collection, item.id, item)

      state.itemsById = {
        ...state.itemsById,
        [item.id]: item
      }
    },

    ITEM_DELETED: (state: ICollectionState, id: string) => {
      state.collection = filter(state.collection, item => item.id !== id)
      state.itemsById = omit(state.itemsById, id)
    },

    ...customStore.mutations
  }

  if (methods && methods.fetch) {
    assign(actions, {
      fetch: async ({ commit }: any, data: any) => {
        commit('START_FETCHING')

        try {
          const items = await triggerMethod(methods, 'fetch', data)
          const collection = Array.isArray(items) ? items : []
          commit('FETCH_SUCCESS', collection)
          return collection
        } catch (e) {
          commit('FETCH_FAILURE')
          throw e
        }
      }
    })

    assign(actions, {
      refresh: async ({ commit }: any, data: any) => {
        commit('START_REFRESHING')

        try {
          const items = await triggerMethod(methods, 'fetch', data)
          const collection = Array.isArray(items) ? items : []
          commit('REFRESH_SUCCESS', collection)
          return collection
        } catch (e) {
          commit('REFRESH_FAILURE')
          throw e
        }
      }
    })

    assign(actions, {
      fetchOrRefresh: ({ state, dispatch }: any, data: any = undefined) => {
        if (state.isFetched) {
          return dispatch('refresh', data)
        } else {
          return dispatch('fetch', data)
        }
      }
    })
  }

  if (methods && methods.fetchById) {
    assign(actions, {
      fetchById: async ({ commit }: any, payload: any) => {
        const item = await triggerMethod(methods, 'fetchById', payload)
        commit('ITEM_FETCHED', item)
        return item
      }
    })
  }

  if (methods && methods.create) {
    assign(actions, {
      create: async ({ commit }: any, data: object) => {
        const object = await triggerMethod(methods, 'create', data)
        commit('ITEM_CREATED', object)
        return object
      }
    })
  }

  if (methods && methods.update) {
    assign(actions, {
      update: async ({ commit }: any, data: object) => {
        const object = await triggerMethod(methods, 'update', data)

        if (object) {
          commit('ITEM_UPDATED', object)
          return object
        }
      }
    })
  }

  if (methods && methods.delete) {
    assign(actions, {
      delete: async ({ commit }: any, payload: any) => {
        const id = typeof payload === 'object' ? payload.id : payload
        await triggerMethod(methods, 'delete', payload)
        commit('ITEM_DELETED', id)
      }
    })
  }

  assign(actions, customStore.actions)

  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
      ...customStore.modules
    }
  }
}
