<template>
  <div class="table-pagination no-gutters row border-top">
    <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
      <div class="pagination-info d-flex mb-3 mb-md-0">
        Résultats 1 à 20 sur 150
      </div>
    </div>
    <div class="col-sm-12 col-md-7">
      <div class="pagination-buttons d-flex justify-content-center justify-content-md-end">
        <button type="button" class="btn btn-default btn-sm disabled">
          <fa-icon :icon="['fas', 'chevron-left']"></fa-icon>
        </button>
        <button type="button" class="ml-2 btn btn-primary active-button btn-sm">1</button>
        <button type="button" class="ml-2 btn btn-default btn-sm">2</button>
        <button type="button" class="ml-2 btn btn-default btn-sm">3</button>
        <button type="button" class="ml-2 btn btn-default btn-sm">
          <fa-icon :icon="['fas', 'chevron-right']"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //
}
</script>

<style scoped lang="scss">
.table-pagination {
  padding: 1rem 0.75rem;

  .pagination-info {
    font-weight: 600;
    white-space: nowrap;
    font-size: 0.9rem;
  }

  .pagination-buttons {
    button {
      box-shadow: 0 0 0 1px rgba(43,45,80,.1), 0 2px 5px 0 rgba(43,45,80,.08), 0 1px 1.5px 0 rgba(0,0,0,.07), 0 1px 2px 0 rgba(0,0,0,.08);
    }

    .active-button {
      pointer-events: none;
    }
  }
}
</style>
