<template>
  <div>
    <transition name="backdrop-fade">
      <div class="modal-backdrop" v-if="isVisible" @click="$modal.close()"></div>
    </transition>

    <transition name="fadeDown">
      <component v-if="isVisible" :is="component" v-bind="data" @cancel="handleCancel" @confirm="handleConfirm"></component>
    </transition>
  </div>
</template>

<script>
export default {
  computed: {
    isVisible () {
      return this.$modal.state.isVisible
    },
    component () {
      return this.$modal.state.component
    },
    data () {
      return this.$modal.state.data
    }
  },
  methods: {
    handleCancel () {
      this.$modal.triggerCancel()
      this.$modal.close()
    },
    handleConfirm (data) {
      this.$modal.triggerConfirm(data)
      this.$modal.close()
    }
  }
}
</script>

<style scoped lang="scss">
.modal-backdrop {
  opacity: 0.5;
}

.backdrop-fade-enter-active,
.backdrop-fade-leave-active {
  transition: opacity .15s linear;
  opacity: 0.5;
}

.backdrop-fade-enter,
.backdrop-fade-leave-active {
  opacity: 0
}
</style>
