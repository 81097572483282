//
// File to load global components
//

import Vue from 'vue'
import AppLayout from '@/layouts/AppLayout.vue'
import AsyncObject from '@/components/common/AsyncObject.vue'
import CollectionTable from '@/components/data/tables/CollectionTable.vue'
import TablePagination from '@/components/data/tables/TablePagination.vue'
import TableLoadMore from '@/components/data/tables/TableLoadMore.vue'

Vue.component('app-layout', AppLayout)
Vue.component('async-object', AsyncObject)
Vue.component('collection-table', CollectionTable)
Vue.component('table-pagination', TablePagination)
Vue.component('table-load-more', TableLoadMore)
