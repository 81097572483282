<template>
  <div class="drawer-root" :class="{ 'drawer-open': open }">
    <div v-if="open" class="drawer-backdrop" @click="handleBackdropClick" />

    <transition :name="transitionName">
      <div v-if="open" class="drawer-panel" :class="{ 'drawer-right': position === 'right' }">
        <div class="drawer-panel-content">
          <div class="drawer-header d-flex justify-content-between align-items-center">
            <div class="drawer-header-content">
              {{ title }}
            </div>
            <div class="drawer-close-button" role="button" @click="handleClose">
              <fa-icon :icon="['fas', 'times']"></fa-icon>
            </div>
          </div>
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean
    },
    position: {
      type: String,
      default: 'left'
    },
    closeOnBackdropClick: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    transitionName () {
      return this.position === 'right' ? 'right-slide' : 'left-slide'
    }
  },
  methods: {
    handleBackdropClick () {
      if (this.closeOnBackdropClick) {
        this.handleClose()
      }
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
// Animations

.left-slide-enter-active,
.left-slide-leave-active,
.right-slide-enter-active,
.right-slide-leave-active
{
  transition: transform 0.4s ease;
}

.left-slide-enter,
.left-slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s
}

.right-slide-enter,
.right-slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s
}

// Component

.drawer-root {
  &.drawer-open {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
    overflow: hidden;
  }

  .drawer-backdrop {
    background-color: rgba(0, 0, 0, .5);
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 10;
  }

  .drawer-panel {
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 999;
    width: 100vw;
    max-width: 600px;
    font-size: 16px;
    overflow: hidden;

    &.drawer-right {
      right: 0;
      left: auto;
    }

    .drawer-header {
      padding: 12px 24px;
      border-bottom: 2px solid #ebedef;
      font-weight: 700;
      font-size: 0.9rem;
    }

    .drawer-close-button {
      cursor: pointer;
    }

    .drawer-panel-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .drawer-body {
      overflow-x: hidden;
      overflow-y: auto;
      flex: 1 1 auto;
      padding: 16px 24px;
    }

    .drawer-footer {
      padding: 16px 24px;
      border-top: 2px solid #ebedef;

      @media (max-width: 767px) {
        min-height: 250px;
      }
    }
  }
}
</style>
