<template>
  <div class="dynamic-form">
    <form-input v-for="field in fields"
                :field="field"
                :locale="locale"
                :key="field.name"
                v-model="values[field.name]"
                @input="handleChange(field.name)"
                :errors="errors[field.name]"
                :values="values"
    />
  </div>
</template>

<script>
import FormInput from './FormInput'

export default {
  props: {
    locale: {
      type: String,
      default: 'en'
    },
    initialValues: {
      type: Object,
      default () {
        return {}
      }
    },
    fields: {
      type: Array,
      default () {
        return []
      }
    },
    errors: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    FormInput
  },
  data () {
    return {
      values: {}
    }
  },
  methods: {
    initValues () {
      const values = {}

      if (Array.isArray(this.fields)) {
        this.fields.forEach((field) => {
          if (field.name) {
            values[field.name] = this.initialValues[field.name] !== undefined ? this.initialValues[field.name] : null
          }
        })
      }

      this.values = { ...values }
      this.$emit('change', values)
    },
    handleChange (fieldName) {
      this.$emit('change', this.values)
      this.$emit('field-change', fieldName, this.values[fieldName])
    }
  },
  created () {
    this.initValues()
  }
}
</script>
