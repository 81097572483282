<template>
  <div class="dynamic-output">
    <form-output v-for="field in fields"
                :field="field"
                :locale="locale"
                :key="field.name"
                :value="values[field.name]" />
  </div>
</template>

<script>
import FormOutput from './FormOutput'

export default {
  props: {
    locale: {
      type: String,
      required: true,
      default: 'en'
    },
    initialValues: {
      type: Object,
      default () {
        return {}
      }
    },
    fields: {
      type: Array,
      default () {
        return []
      }
    }
  },
  components: {
    FormOutput
  },
  data () {
    return {
      values: {}
    }
  },
  methods: {
    initValues () {
      const values = {}

      if (Array.isArray(this.fields)) {
        this.fields.forEach((field) => {
          if (field.name) {
            values[field.name] = this.initialValues[field.name] ? this.initialValues[field.name] : null
          }
        })
      }

      this.values = values
    }
  },
  created () {
    this.initValues()
  }
}
</script>
