<template>
  <div class="pt-4 pb-4 pb-sm-0 pr-4 d-flex justify-content-between align-items-center">
    <div><router-link to="/"><img width="200" src="@/assets/images/logo/inline-logo-le-bon-panier-black.svg" alt="Le bon panier.ca" class="d-flex d-md-none px-2"></router-link></div>
    <div class="d-flex align-items-center">
      <div class="mr-md-4 mr-3 d-flex align-items-center">
        <fa-icon :icon="['far', 'question-circle']" class="support-icon" @click="openSupportRequests"></fa-icon>
      </div>
      <user-dropdown></user-dropdown>
      <fa-icon v-if="isRegistrationCompleted || hasSkippedOnboarding"
               :icon="['fas', 'bars']"
               class="m-3 icon d-block d-md-none"
               @click="toggleMenu" />
    </div>
  </div>
</template>

<script>
import UserDropdown from './UserDropdown'

export default {
  components: {
    UserDropdown
  },
  computed: {
    isRegistrationCompleted () {
      return this.$store.getters.isRegistrationCompleted
    },
    hasSkippedOnboarding () {
      return this.$store.getters.hasSkippedOnboarding
    }
  },
  methods: {
    openSupportRequests () {
      return this.$router.push({ name: 'supportRequests.index' })
    },
    toggleMenu () {
      this.$store.commit('TOGGLE_MENU')
    }
  }
}
</script>

<style scoped lang="scss">
.support-icon {
  font-size: 28px;
  color: #63B3ED;
  opacity: 0.9;
  cursor: pointer;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  color: #000;
  position: relative;
  z-index: 1;
}
</style>
