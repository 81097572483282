import Vue from 'vue'

const onboardingSteps = ['legalProfile', 'bankAccount', 'taxes', 'publicProfile', 'completed']

const routesByStep: Record<string, string> = {
  legalProfile: '/onboarding/enterprise',
  bankAccount: '/onboarding/bank-account',
  taxes: '/onboarding/taxes',
  publicProfile: '/onboarding/public-profile'
}

export default Vue.extend({
  computed: {
    currentStep (): string {
      return this.$store.getters.getCurrentRegistrationStep
    },
    currentStepIndex (): number {
      return onboardingSteps.indexOf(this.$store.getters.getCurrentRegistrationStep)
    },
    isRegistrationCompleted (): boolean {
      return this.$store.getters.isRegistrationCompleted
    },
    hasSkippedOnboarding (): boolean {
      return this.$store.getters.hasSkippedOnboarding
    }
  },
  methods: {
    getStepIndex (step: string): number {
      return onboardingSteps.indexOf(step)
    },
    isStepCompleted (step: string): boolean {
      return this.getStepIndex(step) < this.currentStepIndex
    },
    canAccessStep (step: string): boolean {
      return this.getStepIndex(step) <= this.currentStepIndex
    },
    redirectIfStepDisabled (step: string): void {
      if (!this.canAccessStep(step)) {
        this.$router.push('/')
      }
    },
    handleCompletedStep (step: string): void {
      const nextStepIndex = this.getStepIndex(step) + 1
      const nextStep = onboardingSteps[nextStepIndex]
      const nextRoute = routesByStep[nextStep] ? routesByStep[nextStep] : '/'

      this.$store.commit('UPDATE_REGISTRATION_STATUS', nextStep)

      this.$router.push(nextRoute)
    },
    goToStepUrl (step?: string): void {
      const nextStep = !step ? onboardingSteps[this.currentStepIndex] : step
      const nextRoute = routesByStep[nextStep] ? routesByStep[nextStep] : '/'
      this.$router.push(nextRoute)
    }
  }
})
