<template>
  <div>
    <template v-if="isLoading">
      <b-spinner></b-spinner>
    </template>

    <div v-else-if="hasError" class="p-5 d-flex justify-content-center align-items-center flex-column">
      <fa-icon :icon="['fas', 'exclamation-triangle']" class="mb-5 error-icon"></fa-icon>
      <p v-if="errorType === 'request'">{{ $t('errors.pageNotFound') }}</p>
      <p v-else>{{ $t('errors.pageLoadError') }}</p>
    </div>

    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    shouldFetch: {
      type: Boolean,
      required: false,
      default: false
    },
    fetchMethod: {
      type: Function,
      required: true
    },
    onFetched: {
      type: Function,
      required: false
    },
    smallSpinner: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isLoading: true,
      hasError: false,
      errorType: null
    }
  },
  methods: {
    async handleFetch () {
      if (typeof this.fetchMethod === 'function') {
        try {
          const response = await this.fetchMethod()

          if (typeof this.onFetched === 'function') {
            this.onFetched(response)
          }

          this.isLoading = false
        } catch (e) {
          this.hasError = true
          this.isLoading = false

          if (e.response && e.response.status && e.response.status >= 400 && e.response.status <= 500) {
            this.errorType = 'request'
          } else {
            this.errorType = 'internal'
          }
        }
      }
    }
  },
  created () {
    if (this.shouldFetch) {
      this.handleFetch()
    } else {
      this.isLoading = false
    }
  }
}
</script>

<style lan="scss" scoped>
.error-icon {
  font-size: 48px;
  opacity: 0.7;
}
</style>
