import http from '@/utils/http'
import { File } from '@/store/types/files'

interface PublicProfileState {
  data: PublicProfileObject;
}

interface PublicProfileObject {
  storeId: string;
  address: string;
  address2: string | null;
  city: string;
  postalCode: string;
  province: string;
  country: string;
  description: string;
  category: string;
  profilePhotoId: string;
  profilePhoto: File;
  bannerPhoto: File;
  tagline: string;
}

export default {
  namespaced: true,

  state: {
    data: null
  },

  mutations: {
    RESET_STATE: (state: PublicProfileState) => {
      state.data = {} as PublicProfileObject
    },

    PROFILE_FETCHED: (state: PublicProfileState, profile: PublicProfileObject) => {
      state.data = profile
    }
  },

  actions: {
    async fetch ({ commit }: any, storeId: string): Promise<PublicProfileObject | null> {
      const { data: profile } = await http.get(`/v1/stores/${storeId}/public-profile`)

      commit('PROFILE_FETCHED', profile)
      return profile
    },

    async update ({ commit }: any, { storeId, payload }: { storeId: string; payload: Partial<PublicProfileObject>}): Promise<PublicProfileObject | null> {
      const { data: profile } = await http.put(`/v1/stores/${storeId}/public-profile`, {
        ...payload
      })

      commit('PROFILE_FETCHED', profile)
      return profile
    }
  }
}
