import { cloneDeep } from 'lodash-es'
import { createPaginatedStore } from '@/utils/store'
import http from '@/utils/http'
import { handleHttpError } from '@/utils/handle-http-error'
import { getQueryParams, IQueryParams } from '@/utils/query-params'
import { sortState, mutations } from '@/utils/store-pagination'

const store = createPaginatedStore({
  fetchById: async ({ id, storeId }) => {
    const { data: shipments } = await http.get(`/v1/stores/${storeId}/return-requests/${id}`)

    return shipments
  }
}, {
  state: {
    textFilter: '',
    statusFilter: '',
    ...sortState
  },

  mutations,

  actions: {
    fetch: async ({ commit, state }: any, data: { page: number | null; storeId: string }) => {
      commit('START_FETCHING')

      const params: IQueryParams = getQueryParams(data, state)

      commit('SET_PAGE', params.page)

      const { data: shipments } = await http.get(`/v1/stores/${data.storeId}/return-requests`, {
        params
      })

      commit('FETCH_SUCCESS', shipments)

      return shipments
    },

    async patchItemsStatus ({ commit, state }: any, payload: { id: string; itemId: string; status: string; rejectionMessage?: string }) {
      try {
        const { itemId, status, rejectionMessage } = payload
        const initialItem = { ...state.itemsById[payload.id] } || {}

        const { data: updatedItem } = await http.patch(`/v1/return-request-item/${itemId}`, {
          status,
          rejectionMessage
        })

        commit('ITEM_UPDATED', {
          ...initialItem,
          returnRequestItems: initialItem.returnRequestItems.map((item: Record<string, any>) => {
            if (item.id !== itemId) {
              return { ...item }
            }

            return {
              ...item,
              status: updatedItem.status,
              rejectionMessage: updatedItem.rejectionMessage
            }
          })
        })
      } catch (e) {
        handleHttpError(e)
      }
    }
  }
})

export default {
  ...store,
  state: () => {
    return cloneDeep(store.state)
  }
}
