import { isEqual } from 'lodash-es'
import http from '@/utils/http'

export const mapDelete = async <T extends { id: string | null }>(initList: T[], newList: T[], endpoint: string): Promise<void> => {
  if (!isEqual(initList, newList)) {
    for (const initItem of initList) {
      if (newList.some((item) => item.id === initItem.id)) {
        continue
      }

      if (!initItem || !initItem.id) {
        continue
      }

      await http.delete(`${endpoint}/${initItem.id}`)
    }
  }
}
