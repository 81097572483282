<template>
  <span>
    <span v-if="!customComponent">
      {{ value }}
    </span>
    <span v-else>
      <component :is="customComponent" :value="object[col.property]" v-bind="bindings"></component>
    </span>
  </span>
</template>

<script>
import { result } from 'lodash-es'

export default {
  props: ['col', 'object'],
  computed: {
    value () {
      if (this.col.method && typeof this.col.method === 'function') {
        return this.col.method(this.object)
      }

      if (this.col.property && this.col.property.indexOf('.') > -1) {
        return result(this.object, this.col.property, '')
      }

      return this.object[this.col.property]
    },
    customComponent () {
      return this.col.component ? this.col.component : false
    },
    bindings () {
      if (this.col.props) {
        return typeof this.col.props === 'function' ? this.col.props(this.object) : this.col.props
      }

      return {}
    }
  }
}
</script>
