import { IPages } from '@/store/types/pages'
import http from '@/utils/http'

export default {
  namespaced: true,

  state: {
    csvProductsDocumentation: {},
    productCategoriesDocumentation: {}
  },

  mutations: {
    SET_CSV_PRODUCTS_DOCUMENTATION (state: any, pageContent: IPages) {
      state.csvProductsDocumentation = pageContent
    },
    SET_PRODUCTS_CATEGORIES_DOCUMENTATION (state: any, pageContent: IPages) {
      state.productCategoriesDocumentation = pageContent
    }
  },

  actions: {
    async fetchCSVProductsDocumentation ({ commit }: any): Promise<void> {
      const { data: pageContent } = await http.get('/v1/pages/documentation-import-csv')

      commit('SET_CSV_PRODUCTS_DOCUMENTATION', pageContent)
    },
    async fetchProductsCategoriesDocumentation ({ commit }: any): Promise<void> {
      const { data: pageContent } = await http.get('/v1/pages/categorie-des-produits')

      commit('SET_PRODUCTS_CATEGORIES_DOCUMENTATION', pageContent)
    }
  }
}
