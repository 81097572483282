<template>
  <div class="modal show d-block" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true" @keydown.esc="closeModal">
    <div class="modal-dialog" :class="[sizeClass, { 'modal-dialog-scrollable': scrollable }]" role="document">
      <div class="modal-content" v-click-outside="closeModal">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="close" aria-label="Close" @click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    size: {
      type: String,
      required: false,
      default: null
    },
    scrollable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sizeClass () {
      const acceptedSizes = ['sm', 'lg', 'xl']
      return this.size && acceptedSizes.indexOf(this.size) > -1 ? `modal-${this.size}` : null
    }
  },
  methods: {
    closeModal () {
      this.$modal.triggerCancel()
      this.$modal.close()
    }
  }
}
</script>

<style scoped>
.modal {
  overflow-y: auto;
}
</style>
