export default [
  {
    path: '/connect/shopify/:connectedStoreId',
    name: 'connect.shopify',
    component: () => import(/* webpackChunkName: "connect" */ '@/views/connect/shopify/Index.vue')
  },
  {
    path: '/connect/shopify/:connectedStoreId/callback',
    component: () => import(/* webpackChunkName: "connect" */ '@/views/connect/shopify/Callback.vue')
  }
]
