<template>
  <div class="fixed-sidebar">
    <div class="sidebar-inner">
      <div class="brand p-4 mb-3">
        <router-link to="/">
          <img width="200" src="@/assets/images/logo/inline-logo-le-bon-panier-white.svg" alt="Le bon panier.ca">
        </router-link>
      </div>

      <div class="nav-title">Étapes d'inscription</div>

      <nav class="nav flex-column">
        <router-link class="nav-link" :class="{ disabled: !canAccessStep('legalProfile') }" to="/onboarding/enterprise" active-class="active">
          <span class="checkmark" v-if="isStepCompleted('legalProfile')">
            <check-circle-icon></check-circle-icon>
          </span>
          Entreprise
        </router-link>

        <router-link class="nav-link" :class="{ disabled: !canAccessStep('bankAccount') }" to="/onboarding/bank-account" active-class="active">
          <span class="checkmark" v-if="isStepCompleted('bankAccount')">
            <check-circle-icon></check-circle-icon>
          </span>
          Compte bancaire
        </router-link>

        <router-link class="nav-link" :class="{ disabled: !canAccessStep('taxes') }" to="/onboarding/taxes" active-class="active">
          <span class="checkmark" v-if="isStepCompleted('taxes')">
            <check-circle-icon></check-circle-icon>
          </span>
          Taxes de vente
        </router-link>

        <router-link class="nav-link" :class="{ disabled: !canAccessStep('publicProfile') }" to="/onboarding/public-profile" active-class="active">
          <span class="checkmark" v-if="isStepCompleted('publicProfile')">
            <check-circle-icon></check-circle-icon>
          </span>
          Profil public
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
import onboarding from '@/mixins/onboarding'
import { CheckCircleIcon } from 'vue-feather-icons'

export default {
  mixins: [onboarding],
  components: {
    CheckCircleIcon
  }
}
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0.5;
}

.checkmark {
  svg {
    width: 16px;
    height: 16px;
    margin-right: 16px;
    color: greenyellow;
  }
}

.fixed-sidebar {
  position: fixed;
  top: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  width: 270px;
  background: #010e1f;
  color: rgba(255, 255, 255, 0.7);

  z-index: 50;

  .sidebar-inner {
    overflow-y: auto;
  }
}

.brand,
.brand a {
  font-size: 20px;
  color: #f8f9fa;
  font-weight: 600;
  user-select: none;
  text-decoration: none;
}

.nav {
  .nav-link {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 500;
    font-size: 15px;
    border-left: 3px solid transparent;
    padding: .7rem 1.5rem;
    display: inline-flex;
    align-items: center;

    &:hover,
    &.active {
      background: #0f1d2d;
    }

    &.active {
      border-left: 3px solid #fff;
    }

    .icon {
      margin-right: 16px;
      color: rgba(255, 255, 255, 0.3);
      width: 18px;
      height: 18px;
    }
  }
}

.nav-title {
  padding: 0.5rem 1rem;
  border-left: 3px solid transparent;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.77rem;
  margin: 8px 0;
}
</style>
