<template>
  <div class="output-group">
    <div class="output-label">{{ outputLabel }}</div>

    <div class="output-value">
      <component
        :is="outputType"
        :options="options"
        :params="params"
        :value="value"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    locale: {
      type: String,
      required: true,
      default: 'en'
    },
    value: {
      required: false,
      default: null
    }
  },
  computed: {
    outputType () {
      const type = this.field.type ? this.field.type : 'text'
      return this.$forms.getOutputComponent(type)
    },
    outputLabel () {
      if (this.field.label && typeof this.field.label === 'string') {
        return this.field.label
      } else if (typeof this.field.label === 'object' && this.field.label[this.locale]) {
        return this.field.label[this.locale]
      }
      return null
    },
    className () {
      return this.field.className ? this.field.className : null
    },
    options () {
      const hasOptions = ['checkboxes', 'multiselect', 'radios', 'select']

      return this.field.type && hasOptions.indexOf(this.field.type) > -1
        ? this.computeOptions() : null
    },
    params () {
      return this.field.params ? this.field.params : null
    }
  }
}
</script>

<style lang="scss" scoped>
.output-group {
  margin: 20px 0;
}

.output-label {
  margin-bottom: 8px;
  font-weight: 500;
}
</style>
