<template>
  <div class="app-layout">
    <menu-drawer class="ztop">
      <navigation />
    </menu-drawer>
    <sidebar v-if="isRegistrationCompleted || hasSkippedOnboarding" class="d-none d-md-block"></sidebar>
    <onboarding-sidebar v-else class="onboarding-sidebar"></onboarding-sidebar>

    <div class="main-body-container">
      <topbar></topbar>
      <div class="main-body-content">
        <slot></slot>
      </div>
      <div class="fixed-bottom-elements">
        <suspended-banner />
        <env-banner />
      </div>
    </div>
    <v-modal-slot></v-modal-slot>
    <v-zoom-image />
  </div>
</template>

<script>
import MenuDrawer from '@/components/nav/MenuDrawer'
import Navigation from '@/components/nav/Navigation'
import Sidebar from '@/components/nav/Sidebar'
import OnboardingSidebar from '@/components/nav/OnboardingSidebar'
import Topbar from '@/components/nav/Topbar'
import EnvBanner from '@/components/common/EnvBanner'
import SuspendedBanner from '@/components/common/SuspendedBanner'

export default {
  components: {
    EnvBanner,
    MenuDrawer,
    Navigation,
    Sidebar,
    SuspendedBanner,
    OnboardingSidebar,
    Topbar
  },
  computed: {
    isRegistrationCompleted () {
      return this.$store.getters.isRegistrationCompleted
    },
    hasSkippedOnboarding () {
      return this.$store.getters.hasSkippedOnboarding
    },
    isSuspended () {
      return this.$store.getters.isSuspended
    }
  }
}
</script>

<style scoped lang="scss">
.canvas__open {
  color: #fff;
  position: relative;
  z-index: 9999999;
}
.app-body {
  position: relative;
  min-height: 100vh;
}

.main-body-container {
  padding-left: 270px;
  min-height: 100vh;
}

.main-body-content {
  display: flex;
  flex: 1;
  min-height: calc(100vh - 65px);
  position: relative;
  min-width: 500px; // TODO: responsive :-)
}
.onboarding-sidebar {
  min-height: 100vh;
}
@media (max-width: 767px) {
  .app-layout {
    display: flex;
    flex-direction: column;
  }
  .onboarding-sidebar {
    width: 100vw;
    min-height: auto;
  }
  .main-body-container {
    padding-left: 0;
    min-height: calc(100vh - 353px);
  }
  .main-body-content {
    min-width: 100vw;
    min-height: auto;
  }
  .onboarding-sidebar {
    height: auto;
    position: static;
    order: 2;
    margin-top: 40px;
  }
}
.fixed-bottom-elements {
  position: fixed;
  bottom: 0;
  right:0;
  left: 270px;
  z-index: 9999;
  @media (max-width: 767px) {
    left: 0;
  }
}
</style>
