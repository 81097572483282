import { cloneDeep } from 'lodash-es'
import { createPaginatedStore } from '@/utils/store'
import http from '@/utils/http'
import { handleHttpError } from '@/utils/handle-http-error'
import { getQueryParams, IQueryParams } from '@/utils/query-params'
import { sortState, mutations } from '@/utils/store-pagination'

const store = createPaginatedStore({
  fetchById: async ({ id }) => {
    const { data: shipments } = await http.get(`/v1/shipments/${id}`)

    return shipments
  }
}, {
  state: {
    textFilter: '',
    statusFilter: '',
    ...sortState
  },

  mutations,

  actions: {
    fetch: async ({ commit, state }: any, data: { page: number | null; storeId: string }) => {
      commit('START_FETCHING')

      const params: IQueryParams = getQueryParams(data, state)

      commit('SET_PAGE', params.page)

      const { data: shipments } = await http.get(`/v1/stores/${data.storeId}/shipments`, {
        params
      })

      commit('FETCH_SUCCESS', shipments)

      return shipments
    },

    async updateTracking ({ commit, state }: any, payload: { shipmentId: string; trackingNumber: string }) {
      try {
        const initialItem = { ...state.itemsById[payload.shipmentId] } || {}

        const { data: updatedItem } = await http.patch(`/v1/shipments/${payload.shipmentId}/tracking-number`, {
          trackingNumber: payload.trackingNumber
        })

        commit('ITEM_UPDATED', {
          ...initialItem,
          trackingNumber: updatedItem.trackingNumber
        })
      } catch (e) {
        handleHttpError(e)
      }
    }
  }
})

export default {
  ...store,
  state: () => {
    return cloneDeep(store.state)
  }
}
