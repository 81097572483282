<template>
  <div class="avatar-container d-inline-block" :class="[className, { 'cursor-pointer': clickable }]" @click="handleClick">
    <div class="avatar" :class="variantClass">
      {{ text || '' }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String
    },
    variant: {
      type: String
    },
    text: {
      type: String
    },
    clickable: {
      type: Boolean
    }
  },
  computed: {
    variantClass () {
      return this.variant ? `variant-${this.variant}` : null
    }
  },
  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
  // TODO: support images
  // TODO: support custom colors
}
</script>

<style lang="scss" scoped>
.avatar {
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  background: #CBD5E0;
  color: black;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  user-select: none;

  &.variant-small {
    font-size: 14px;
  }
}
</style>
