import Vue from 'vue'
import { getErrorType } from './http-errors'

export const handleHttpError = (e: any) => {
  const errorType = getErrorType(e)

  if (errorType === 'forbidden') {
    Vue.toasted.error('Vous ne disposez pas des permissions nécessaires.').toString()
  } else {
    Vue.toasted.error("Une erreur s'est produite. Veuillez réessayer.").toString()
  }
}
