import Vue, { VueConstructor } from 'vue'
import ZoomImage from './ZoomImage.vue'
import { VueZoomImageObject } from './types'

const vm = new Vue({
  data: {
    isVisible: false,
    sources: [],
    slide: 0
  }
})

const VueZoomImage: VueZoomImageObject = {
  state: vm.$data,

  open (sources: string | string[] | null | undefined, slide = 0): void {
    if (sources) {
      this.state.slide = slide
      this.state.sources = Array.isArray(sources) ? sources : [sources]
      if (this.state.sources.length > 0) {
        this.state.isVisible = !this.state.isVisible
      }
    }
  }
}

export const ZoomImagePlugin = {
  install: (Vue: VueConstructor) => {
    Vue.component('v-zoom-image', ZoomImage)

    Vue.prototype.$zoomImage = VueZoomImage
    Vue.zoomImage = VueZoomImage
  }
}
