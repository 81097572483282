import { IPaginatedCustomState } from '@/store/types/customState'

export const sortState = {
  sortBy: '',
  sortDirection: 'DESC'
}

export const mutations = {
  SET_TEXT_FILTER: (state: IPaginatedCustomState, value: string) => {
    state.textFilter = value
  },

  SET_STATUS_FILTER: (state: IPaginatedCustomState, value: string) => {
    state.statusFilter = value
  },

  SET_SORT: (state: IPaginatedCustomState, value: string) => {
    state.sortBy = value
  },

  SET_TYPE_FILTER: (state: IPaginatedCustomState, value: string) => {
    state.typeFilter = value
  },

  SET_SORT_DIRECTION: (state: IPaginatedCustomState, value: 'DESC' | 'ASC') => {
    state.sortDirection = value
  }
}
