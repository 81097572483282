import http from '@/utils/http'

interface ProfileState {
  data: ProfileObject;
}

interface ProfileObject {
  storeId: string;
  businessType: string;
  firstName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  companyName: string | null;
  federalCompanyNumber: string | null;
  provincialCompanyNumber: string | null;
  address: string;
  address2: string | null;
  city: string;
  postalCode: string;
  province: string;
  country: string;
  representativeAddress: string | null;
  representativeAddress2: string | null;
  representativeCity: string | null;
  representativePostalCode: string | null;
  representativeProvince: string | null;
  representativeCountry: string | null;
}

export default {
  namespaced: true,

  state: {
    data: null
  },

  mutations: {
    RESET_STATE: (state: ProfileState) => {
      state.data = {} as ProfileObject
    },

    PROFILE_FETCHED: (state: ProfileState, profile: ProfileObject) => {
      state.data = profile
    }
  },

  actions: {
    async fetch ({ commit }: any, storeId: string): Promise<ProfileObject | null> {
      const { data: profile } = await http.get(`/v1/stores/${storeId}/profile`)

      commit('PROFILE_FETCHED', profile)
      return profile
    },

    async update ({ commit }: any, { storeId, payload }: { storeId: string; payload: Partial<ProfileObject>}): Promise<ProfileObject | null> {
      const { data: profile } = await http.put(`/v1/stores/${storeId}/profile`, {
        ...payload
      })

      commit('PROFILE_FETCHED', profile)
      return profile
    }
  }
}
