<template>
  <div class="table-pagination no-gutters row border-top">
    <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
      <div class="pagination-info d-flex mb-3 mb-md-0">
        20 résultats sur 150
      </div>
    </div>
    <div class="col-sm-12 col-md-7">
      <div class="pagination-buttons d-flex justify-content-center justify-content-md-end">
        <button type="button" class="btn btn-default btn-sm" @click="loadMore">Afficher plus</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    loadMore () {
      console.log('load more clicked...')
    }
  }
}
</script>

<style scoped lang="scss">
.table-pagination {
  padding: 1rem 0.75rem;

  .pagination-info {
    font-weight: 600;
    white-space: nowrap;
    font-size: 0.9rem;
  }

  .pagination-buttons {
    .btn-default {
      color: #4d5969;
      background-color: #fff;
      border-color: #fff;
      box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    }

    .btn-default:hover {
      color: #4d5969;
      background-color: #fff;
      border-color: #fff;
    }

    .btn-default:focus, .btn-default.focus {
      color: #4d5969;
      background-color: #fff;
      border-color: #fff;
    }

    .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active,
    .show > .btn-default.dropdown-toggle {
      color: #4d5969;
      background-color: #e6e6e6;
      border-color: #dfdfdf;
    }

    .btn-default:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled).active:focus,
    .show > .btn-default.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(228, 230, 233, 0.5);
    }
  }
}
</style>
