<template>
  <div v-if="shouldShow"
       class="env-banner alert mb-0 rounded-0 text-center d-flex align-items-center justify-content-center"
       :class="`alert-${banner.color}`"
  >
    Actuellement sur <span class="font-weight-bold ml-1 text-capitalize">{{ banner.text }}</span>
    <button class="btn btn-link btn-sm shadow-none hide-btn" @click="shouldShow = false">
      <fa-icon :icon="['fas', 'times']"></fa-icon>
    </button>
  </div>
</template>

<script>
import config from '@/config'

export default {
  data () {
    return {
      shouldShow: config.env !== 'production'
    }
  },
  computed: {
    banner () {
      let color
      let text

      if (config.env === 'production') {
        return {}
      }

      switch (config.env) {
        case 'dev':
          color = 'success'
          text = 'development'
          break
        case 'staging':
          color = 'warning'
          text = 'staging'
          break
        case 'local':
          color = 'info'
          text = 'local'
          break
        default:
          color = 'primary'
          text = config.env
      }

      return { color, text }
    }
  }
}
</script>

<style lang="scss" scoped>
.env-banner {
  height: 36px;
  max-height: 36px;
}
.hide-btn {
  position: absolute;
  right: 0;
}
</style>
