import Vue from 'vue'

export default Vue.extend({
  props: {
    inputId: {
      type: String,
      required: true
    },
    helpTextId: {
      default: null
    },
    label: {
      type: String
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: null
    },
    params: {
      type: Object,
      default () {
        return {}
      }
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange (data: any) {
      const value = typeof data === 'object' && data.target ? data.target.value : data
      this.$emit('value-change', value)
    },
    handleBlur () {
      this.$emit('value-blur', this.value)
    }
  }
})
