<template>
  <div v-if="isSuspended && !hideSuspendedBanner" class="suspended-banner alert alert-danger mb-0 rounded-0 text-left">
    <h4>Votre boutique a été suspendue.</h4>
    <p class="mb-0">
      <span class="d-block">Aucun de vos produits ne peut être créé ni vendu pour le moment.</span>
      Pour plus d'informations ou d'assistance, veuillez soumettre une <router-link to="/support/requests">demande de support</router-link> et nous examinerons votre compte.
    </p>
    <button class="btn btn-link btn-sm shadow-none hide-btn" @click="hideSuspendedBanner = true">
      <fa-icon :icon="['fas', 'times']"></fa-icon>
    </button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hideSuspendedBanner: false
    }
  },
  computed: {
    isSuspended () {
      return this.$store.getters.isSuspended
    }
  }
}
</script>

<style lang="scss" scoped>
.suspended-banner {
  a {
    color: inherit;
    text-decoration: underline;
  }
  .hide-btn {
    position: absolute;
    right: 0;
    top: 0.75rem;
  }
}
</style>
