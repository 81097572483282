import { cloneDeep } from 'lodash-es'
import { createPaginatedStore } from '@/utils/store'
import http from '@/utils/http'
import { getQueryParams, IQueryParams } from '@/utils/query-params'
import { sortState, mutations } from '@/utils/store-pagination'

const store = createPaginatedStore({
  fetchById: async ({ id, storeId }) => {
    const { data: orders } = await http.get(`/v1/stores/${storeId}/orders/${id}`)

    return orders
  }
}, {
  state: {
    textFilter: '',
    statusFilter: '',
    ...sortState
  },

  mutations,

  actions: {
    fetch: async ({ commit, state }: any, data: { page: number | null; storeId: string }) => {
      commit('START_FETCHING')

      const params: IQueryParams = getQueryParams(data, state)

      commit('SET_PAGE', params.page)

      const { data: orders } = await http.get(`/v1/stores/${data.storeId}/orders`, {
        params
      })

      commit('FETCH_SUCCESS', orders)

      return orders
    }
  }
})

export default {
  ...store,
  state: () => {
    return cloneDeep(store.state)
  }
}
