<template>
  <tr class="hide-table-padding" v-b-toggle="collapsedId">
    <td :colspan="colspan" :class="borderVariantClass">
      <label class="ml-2 my-1 d-block cursor-pointer" :class="`text-${variant}`">
        <fa-icon :icon="['fas', 'exclamation-circle']" class="mr-1" size="sm"/>
        {{ label }}
      </label>
      <b-collapse :id="collapsedId" class="ml-3 text-muted">
        <ul v-if="Array.isArray(messages)">
          <li v-for="(message, index) in messages" :key="`message-${index}-${collapsedId}`">
            {{ message }}
          </li>
        </ul>
        <label v-else>{{ messages }}</label>
      </b-collapse>
    </td>
  </tr>
</template>

<script>
import { BCollapse, VBToggle } from 'bootstrap-vue'

export default {
  components: {
    BCollapse
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    colspan: {
      type: Number,
      required: true
    },
    collapsedId: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    messages: {
      type: [Array, String],
      required: false,
      default: () => {
        return []
      }
    },
    variant: {
      type: String,
      required: false,
      default: 'danger',
      validator: value => ['danger', 'warning'].includes(value)
    }
  },
  computed: {
    borderVariantClass () {
      return this.variant === 'danger' ? 'border-left-danger' : 'border-left-warning'
    }
  }
}
</script>

<style lang="scss" scoped>
  .hide-table-padding {
    td {
      border-top: none;
      border-bottom: none;
      padding: 0;
    }
    .border-left-danger {
      border-left: 5px solid #dc3545;
    }
    .border-left-warning {
      border-left: 5px solid #ffc107;
    }
    &:hover {
       background-color: transparent;
     }
  }
</style>
