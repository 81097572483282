import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import App from './App.vue'
import router from './router'
import store from './store'
import './firebase'
import './initialize'
import './globals'

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: 'https://62ffb3cd94934d1a800b1ec0734a8cf7@o574686.ingest.sentry.io/5726053',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.VUE_APP_ENV === 'production' ? 0.2 : 1.0,

  // Make sure error shows up in developer console when not running in production
  logErrors: process.env.VUE_APP_ENV !== 'production'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
