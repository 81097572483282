<template>
  <vue-easy-lightbox
    move-disabled
    :visible="isOpen"
    :imgs="sources"
    :index="slide"
    @hide="close"
  />
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  components: {
    VueEasyLightbox
  },
  computed: {
    isOpen () {
      return this.$zoomImage.state.isVisible
    },
    sources () {
      return this.$zoomImage.state.sources
    },
    slide () {
      return this.$zoomImage.state.slide
    }
  },
  methods: {
    close () {
      this.$zoomImage.state.isVisible = false
    }
  }
}
</script>
