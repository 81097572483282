import { find } from 'lodash-es'
import { createCollectionStore } from '@/utils/store'
import http from '@/utils/http'

export default createCollectionStore({
  fetch: async (storeId: string) => {
    const { data: taxRates } = await http.get(`/v1/stores/${storeId}/tax-rates`)
    return taxRates
  },

  create: async ({ storeId, ...payload }: Record<string, any>) => {
    const { data: taxRate } = await http.post(`/v1/stores/${storeId}/tax-rates`, {
      ...payload,
      countryCode: 'CA'
    })

    return taxRate
  },

  update: async ({ storeId, id, ...payload }: Record<string, any>) => {
    const { data: taxRate } = await http.put(`/v1/stores/${storeId}/tax-rates/${id}`, {
      ...payload
    })

    return taxRate
  },

  delete: async ({ storeId, id }: Record<string, any>) => {
    await http.delete(`/v1/stores/${storeId}/tax-rates/${id}`)
  }
}, {
  getters: {
    getAvailableTaxes: (state: any) => {
      const taxes: Array<{ id: string; label: string; code: string }> = []

      for (const taxRate of state.collection) {
        if (!find(taxes, { id: taxRate.taxId }) && taxRate.tax) {
          taxes.push({
            id: taxRate.taxId,
            code: taxRate.tax.code,
            label: taxRate.tax.label
          })
        }
      }

      return taxes
    }
  }
})
